.btn-lg {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  border-radius: 100%;
  background-color: #0dcaf0;
  border: none;
  width: 50px;
  height: 50px;
}

.fa-arrow-up {
  color: #ffffff;
}
