:root {
  --primary: #fff;
  --secondary: #0dcaf0;
}
.main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

/* .sub-heading {
  font-size: 25px;
  font-weight: 100;
} */

.main-container > h1 {
  color: #0dcaf0;
  font-size: 100px;
  margin: 80px;
}

.main-container > p {
  margin-top: 8px;
  text-align: center;
  color: #000;
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.main-btns {
  margin-top: 32px;
}

.btn {
  margin: 6px;
}

.CareerFormBox {
  margin: 25px;
  padding: 50px 25px;
  border: 2px solid #0dcaf0;
}

.Cf-Upload-button {
  text-align: center;
}

/* .Cf-submit {
  border: 2px solid #0dcaf0;
  margin: 0px 25px;
  margin-top: -15px;
  margin-bottom: 50px;
  text-align: center;
}

.Cf-submit:hover {
  background-color: #0dcaf0;
  margin: 0px 25px;
  margin-top: -15px;
  margin-bottom: 50px;
  text-align: center;
} */

.MenuItem {
  color: black;
}

/* Increase the specificity */
.MenuItem.Mui-selected {
  color: black;
}

.Cf-submit-button {
  color: #0dcaf0;
  border: 2px solid #0dcaf0;
  background-color: #ffffff;
  margin-left: 25px;
  margin-right: 50px;
  margin-top: 5px;
  margin-bottom: 25px;
  padding: 20px 212px;
  text-align: center;
  text-decoration: none;

  font-size: 16px;
}

.Cf-submit-button:hover {
  background-color: #0dcaf0;
  color: white;
}

@media screen and (max-width: 960px) {
  .main-container > h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 768px) {
  .main-container > h1 {
    font-size: 50px;
  }
  .main-container > p {
    font-size: 18px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
