video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
.hero-container > img {
  object-fit: cover;
  /* width: 80%;
  height: 80%; */
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */

  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 90, 131, 0.5);
  object-fit: contain;
}

.hero-sec-title {
  font-weight: 700;
  padding-left: 200px;
  padding-right: 200px;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;

  /* margin-top: -100px; */
}

.hero-container > p {
  margin-top: 8px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif; */
  padding-left: 120px;
  padding-right: 120px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    /* margin-top: -150px; */
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    /* margin-top: -100px; */
  }

  .hero-container > p {
    font-size: 18px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
