.wa-btn-lg {
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: block;
  border-radius: 100%;
  background-color: #20ff0c;
  border: none;
  width: 50px;
  height: 50px;
  text-align: center;
}

.d-wa-btn-lg {
  position: fixed;
  bottom: 40px;
  right: 20px;
  display: block;
  border-radius: 100%;
  background-color: #20ff0c;
  border: none;
  width: 50px;
  height: 50px;
  text-align: center;
}

.fa-whatsapp {
  color: #fff;
  font-size: 32px;
}

/* add css module styles here (optional) */

.floatingWpp {
  position: fixed;
  bottom: 15px;
  left: 15px;
  font-size: 16px;
  transition: bottom 0.2s;
}

.floatingWpp .floatingWppButton {
  position: relative;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(60, 60, 60, 0.4);
  transition: box-shadow 0.2s;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floatingWpp .floatingWppButton img,
.floatingWpp .floatingWppButton svg {
  width: 70%;
  height: 70%;
}

.floatingWpp:hover {
  bottom: 17px;
}

.floatingWpp:hover .floatingWppButton {
  box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.4);
}

.floatingWpp .floatingWppPopup {
  /*border: 2px solid white;*/
  border-radius: 6px;
  background-color: pink;
  position: absolute;
  overflow: hidden;
  padding: 0;
  box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
  width: 0px;
  height: 0px;
  bottom: 0;
  opacity: 0;
  transition: bottom 0.1s ease-out, opacity 0.2s ease-out;
  transform-origin: bottom;
}

.floatingWpp .floatingWppPopup.active {
  padding: 0 12px 12px 12px;
  width: 260px;
  height: auto;
  bottom: 82px;
  opacity: 1;
}

.floatingWpp .floatingWppPopup .floatingWppMessage {
  background-color: pink;
  padding: 8px;
  border-radius: 0px 5px 5px 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition: opacity 0.2s;
}

.floatingWpp .floatingWppPopup.active .floatingWppMessage {
  opacity: 1;
  transition-delay: 0.2s;
}

.floatingWpp .floatingWppPopup .floatingWppHead {
  text-align: right;
  color: white;
  margin: 0 -15px 10px -15px;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.floatingWpp .floatingWppInputMessage {
  background-color: pink;
  margin: 10px -15px -15px -15px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.floatingWpp .floatingWppInputMessage textarea {
  border: 1px solid pink;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px;
  margin: 10px 0;
  width: 100%;
  max-width: 100%;
  font-family: inherit;
  font-size: inherit;
  resize: none;
}

.floatingWpp .floatingWppBtnSend {
  margin-left: 12px;
  font-size: 0;
  cursor: pointer;
  height: 18px;
  width: 20px;
}
