.testimonial-container {
  width: 100%;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.testimonial-header-title > h2 {
  margin-top: 50px;
  color: #000;
  font-size: 36px;
}

.testimonial-header-title > h2 > span {
  color: #0dcaf0;
  font-size: 36px;
}

.testimonial-subheader-text {
  font-size: 18px;
  font-weight: 400;
  color: #0dcaf0;
  border: 1px solid #0dcaf0;
  display: inline-block;
  padding: 5px 5px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-align: center;
}

.testimonial-subheader {
  text-align: center;
}
