:root {
  --primary: #fff;
  --secondary: #0dcaf0;
}

.zip-btn {
  padding: 8px 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 2px solid pink;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--outline--Secondary {
  background-color: transparent;
  color: var(--secondary);
  padding: 8px 20px;
  border: 1px solid var(--secondary);
  transition: all 0.3s ease-out;
}

.btn--Secondary {
  background-color: var(--secondary);
  color: #0dcaf0;
  border: 1px solid var(--primary);
  padding: 8px 20px;
  margin: 10px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  margin: 10px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #0dcaf0;
  color: #000;
  border: none;
  transition: 250ms;
}
