.ourClients {
  padding: 1rem;
  background: #000;
  text-align: center;
  overflow-x: hidden;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
}
.cw-container-row {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.cw-container-col {
  float: left;
  width: 50%;
  padding: 5px;
  align-items: center;
  text-align: center;
  min-height: 1px;

  /*  vertical-align: middle;
  justify-content: center; */
}

.cw-container-row:after {
  content: "";
  display: table;
  clear: both;
}

.cw-container-title {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #0dcaf0;
}

.ourClients-container-text {
  align-items: center;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

/* .cw-container-link > a:hover {
  font-size: 110%;
} */

.cw-container-col > img {
  max-width: 100%;
  height: auto;
  display: flex;
  border-style: none;
  padding: 20px;
}

.ourClients-container {
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
  /* max-width: 1420px; */
  width: 100%;
  margin: 0;
}

.ourClients-text-section {
}

.ourClient-sub-heading {
  padding: 50px 100px;
  margin-top: -50px;
  color: #9d9d9d;
}

.ourClients-header-text {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
}

.ourClients-header-Blue-text {
  font-size: 40px;
  font-weight: 700;
  color: #0dcaf0;
}

/* .clients-img-r2 {
  transform: translateX(150px);
  animation: original-clients-img-r2 1s ease forwards;
}

@keyframes original-clients-img-r2 {
  to {
    transform: translateX(0);
  }
} */

.ourClients > h2 {
  margin-top: 50px;
  color: #fff;
  font-size: 36px;
}

.ourClients > h2 > span {
  color: #0dcaf0;
  font-size: 36px;
}
.ourClients-container > h2 {
  font-size: 18px;
  font-weight: 400;
  color: #0dcaf0;
}

.ourClients-wrapper {
  align-items: center;
  margin: 10px 0 40px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

/* .cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
} */

/* .fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
} */

/* .cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
} */

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
